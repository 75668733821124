<div class="container pt-4">

  <div class="row">
    <div class="col-md-4 col-12 mt-5">
      <div class="features text-center">
        <div class="image position-relative d-inline-block">
          <img src="assets/images/icon/insurance.svg" class="avatar avatar-small" alt="">
        </div>

        <div class="content mt-4">
          <h4 class="title-2">Aprendizaje Continuo</h4>
          <p class="text-muted mb-0">Nuestro equipo de trabajo es capacitado constantemente sobre marcos de trabajo, nuevas tecnologías y buenas prácticas.</p>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 col-12 mt-5">
      <div class="features text-center">
        <div class="image position-relative d-inline-block">
          <img src="assets/images/icon/video.svg" class="avatar avatar-small" alt="">
        </div>

        <div class="content mt-4">
          <h4 class="title-2">Conocimiento en Tecnologías</h4>
          <p class="text-muted mb-0">Profesionales calificados y certificado en diferentes tecnologías, tales como Microsoft, Java, Google, ETLs, RPA, etc.</p>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-md-4 col-12 mt-5">
      <div class="features text-center">
        <div class="image position-relative d-inline-block">
          <img src="assets/images/icon/server.svg" class="avatar avatar-small" alt="">
        </div>

        <div class="content mt-4">
          <h4 class="title-2">Ambientes de Desarrollo</h4>
          <p class="text-muted mb-0">Soluciones apropiadas para diferentes entornos de construcción. Actualmente disponemos ambientes en nube (AWS, Azure, Google Cloud) y on-premise.</p>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
