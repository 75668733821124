import { Component, OnInit } from '@angular/core';
import { CanonicalService } from './canonical.service';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.css']
})
export class MasterPageComponent implements OnInit {

  addclass: string;
  constructor(
    private canonicalService: CanonicalService

  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
  } 

  onActivate(componentReference: any) {
    this.addclass = componentReference.navClass;
  }
}