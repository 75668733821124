<div class="container mt-4">
  <div class="row">

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">RUP</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Scrum</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Kamban</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">ITIL</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">COBIT</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">TOGAF</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="award" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Zachman</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">.Net / .Net Core</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Java SE / J2EE</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Spring Framework</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">PHP 7.0+</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">AngularJS / Angular 5.0+</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Javascript</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">NodeJS</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Python</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">GO (Google)</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="code" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Scala</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">WSO2</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">JBoss Fuse</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Apache Camel</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">MSIS / MSRS</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="settings" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Pentaho PDI / PR</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="check-circle" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">JMeter / JUnit</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="check-circle" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Selenium</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="check-circle" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Katalon Studio</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="git-branch" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">CI/CD Jenkins</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="git-branch" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">CI/CD AzureDevops</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="git-branch" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Git / Subversion / Azure</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="cloud" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Amazon Web Services</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="cloud" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Microsoft Azure</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="cloud" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">Google Cloud</h4>
        </div>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-3 col-md-6 mb-2 pb-0">
      <div class="media key-feature align-items-center p-2 rounded shadow">
        <div class="icon text-center rounded-circle mr-3">
          <i-feather name="plus" class="fea icon-ex-md text-primary"></i-feather>
        </div>
        <div class="media-body">
          <h4 class="title mb-0">+ Tecnologías</h4>
        </div>
      </div>
    </div>
    <!--end col-->

  </div>
  <!--end row-->
</div>