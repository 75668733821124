<!-- Hero Start -->
<section class="bg-half-170 bg-primary d-table w-100" id="home" style="background: url('assets/images/Fondos/fd4.jpg') center;background-size: cover;"  id="home">
  <div class="bg-overlay Not"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="title-heading">
          <h1 class="heading text-white title-dark mb-4">Gestión de Talentos</h1>
          <p class="para-desc mx-auto text-white-50">
            Construimos soluciones de acuerdo a sus necesidades,
            con las mejores buenas prácticas de la industria, alineadas 
            al negocio y con la mayor calidad posible.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="features-absolute" style="margin: -260px 0 0px;">
          <div class="row">
            

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/app_development_SVG.svg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Aplicaciones Web y Móvil</a></h5>
                  <p class="text-muted mb-0">
                    Aplicaciones modernas, escalables, robustos y confiables. Utilizamos tecnologías de punta, 
                    con diseño de interfaces enrriquecidas y atractivas, con apoyo de nuestros líderes de UX y 
                    Diseño Gráfico. Ofrecemos aplicaciones móviles nativas para Android y IOS, e incorporamos 
                    funcionalidad OnLine y OffLine para que la operación de su compañía nunca se detenga.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/SEO_SVG.svg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Integración de Herramientas Empresariales</a></h5>
                  <p class="text-muted mb-0">
                    Construimos soluciones altamente interoperables y seguras, por medio de la integración de 
                    componentes desacoplados y fácilmente reutilizables, optimizando el retorno de inversión, 
                    la reducción de costos y tiempos en construcción e implementación de nuevas capacidades de negocio.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/Startup_SVG.svg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Modernización a Nube</a></h5>
                  <p class="text-muted mb-0">
                    Migramos o construimos aplicaciones hacia nubes públicas o privadas, dejando atrás sistemas 
                    monolítos y complejos de mantener, en subsistemas mas pequeños y estables utilizando arquitecturas 
                    basadas en componentes y/o microservcios.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/Creativity-bro.svg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Arquitecturas Modernas y Evolutivas</a></h5>
                  <p class="text-muted mb-0">
                    Diseñamos sistemas con altos estándares de calidad, gracias a nuestro enfoque de arquitectura evolutiva. 
                    Potenciamos la continuidad de la operación, con alta disponibilidad, capacidad de crecimiento acelerado, 
                    e integración de componentes desacoplados.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-12 mt-4 pt-2">
              <div class="media features p-4 bg-white shadow rounded">
                <div class="icons m-0 rounded h2 text-primary text-center px-3" style="background: none;">
                  <img _ngcontent-bav-c185="" src="assets/images/illustrator/user_interface.svg" alt="" class="avatar avatar-small">
                </div>
                <div class="content ml-4">
                  <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Software a la Medida</a></h5>
                  <p class="text-muted mb-0">
                    Todas nuestras soluciones están pensadas exclusivamente para su negocio, bien sea que se construyan desde cero, 
                    o que se realice integración de diferentes herramientas. Entendemos que su negocio es importante, y por ello, 
                    ofrecemos alternativas de solución acordes a sus expectativas y oportunidades de crecimiento.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <app-transversales></app-transversales>
  <app-tecnologias></app-tecnologias>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-12 mt-4 pt-2 text-center">
            <a routerLink="/aboutus" class="btn btn-primary mb-2 mr-2">Conózcanos</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

</section>
<!--end section-->

<section class="section bg-light">

  <div class="container mt-0 mt-0">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="section-title text-md-left text-center">
          <h4 class="title mb-4">Inquietudes Sobre el Servicio ?</h4>
          <p class="text-muted mb-0 para-desc">
            En <span class="text-primary font-weight-bold">MDSIS,</span> 
            nuestro grupo de profesionales está siempre dispuesto a atender sus inquietudes 
            y resolverlas en el menor tiempo. <br><br>
            También puede consultar información de los siguientes servicios:
          </p>

          <ul class="list-unstyled text-muted mt-4 mb-0">
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/automation" class="text-primary h6">
                Automatización
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/business-intelligence" class="text-primary h6">
                Inteligencia de Negocios
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/digital-transformation" class="text-primary h6">
                Transformación Digital
              </a>
            </li>
            <li class="mb-0">
              <span class="text-primary h5 mr-2">
                <i class="uim uim-check-circle"></i>
              </span>
              <a routerLink="/it-consulting" class="text-primary h6">
                Consultoría en TI
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="faq-content">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark"> Qué metodologías utilizamos ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Utilizamos todas la herramientas, metodologías, y técnicas posibles en la industria 
                  para garantizar la mayor calidad en el servicio. Nuestros líderes de área 
                  guían y evalúan constantemente el uso de las buenas prácticas según los 
                  marcos de trabajo involucrados en el proyecto.
                  <a routerLink="/aboutus" class="text-primary h6">Más información
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </p>
              </ng-template>
            </ngb-panel>
            
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Qué experiencia tenemos en Fábrica de Software ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Contamos con más de 12 años de experiencia en el mercado. Hemos trabajado en proyectos 
                  de aplicaciones robustas, misionales, migraciones de tecnologías y optimizaciones de 
                  rendimiento para grandes organizanizaciones nacional e internacional.
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Qué acompañamiento brindamos ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Acompañamos a los equipos interesados durante todo el proyecto, desde su 
                  etapa inicial hasta su entrega en ambientes productivos. Ofrecemos 
                  asesoría en la definición y validación del proyecto, para garantizar la mejor propuesta 
                  y solución, de acuerdo a las necesidades que tenga la compañía y en pro de las operaciones 
                  del negocio. Consulte también nuestro servicio de 
                  <a routerLink="/it-consulting" class="text-primary h6">Consultoría en TI
                    <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
                  </a>
                </p>
              </ng-template>
            </ngb-panel>

            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Qué nivel de calidad involucramos ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Implementamos prácticas de integración contínua y despliegue contínuo, acompañado de 
                  evaluación automática de métricas de códificación. Utilizamos planes de pruebas de 
                  calidad con herramientas de automatización, y diseño de pruebas de rendimiento, carga y 
                  estrés, para garantizar los criterios de calidad acordados en todos los componentes de la solución.
                </p>
              </ng-template>
            </ngb-panel>
            
          </ngb-accordion>

        </div>
      </div>
    </div>

    <div class="row mt-2 pt-md-2 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <a href="page-contact-two" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contáctenos</a>
        </div>
      </div>
    </div>
  
  </div>
  <!--end container-->
</section>
<!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
