import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexModernBusinessComponent } from './core/components/index-modern-business/index-modern-business.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageAboutusTwoComponent } from './core/components/page-aboutus-two/page-aboutus-two.component';
import { PageContactThreeComponent } from './core/components/page-contact-three/page-contact-three.component';
import { ServiceAutomatizacionComponent } from './core/components/service-automatizacion/service-automatizacion.component';
import { ServiceConsultoriaComponent } from './core/components/service-consultoria/service-consultoria.component';
import { ServiceFabricaSofwareComponent } from './core/components/service-fabrica-sofware/service-fabrica-sofware.component';
import { ServiceGestionProyectosComponent } from './core/components/service-gestion-proyectos/service-gestion-proyectos.component';
import { ServiceGestionTalentosComponent } from './core/components/service-gestion-talentos/service-gestion-talentos.component';
import { ServiceInteligenciaNegocioComponent } from './core/components/service-inteligencia-negocio/service-inteligencia-negocio.component';
import { ServiceTransformacionDigitalComponent } from './core/components/service-transformacion-digital/service-transformacion-digital.component';
import { EmailAlertComponent } from './email/email-alert/email-alert.component';
import { EmailConfirmationComponent } from './email/email-confirmation/email-confirmation.component';
import { EmailInvoiceComponent } from './email/email-invoice/email-invoice.component';
import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { mdsisConstants } from './shared/common/mdsis.constants';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { 
        path: '', 
        component: IndexModernBusinessComponent, 
        data: mdsisConstants.data_Index
      },
      { 
        path: mdsisConstants.path_AboutUs, 
        component: PageAboutusTwoComponent, 
        data : mdsisConstants.data_AboutUs
      },
      { 
        path: mdsisConstants.path_ContactUs, 
        component: PageContactThreeComponent, 
        data : mdsisConstants.data_ContactUs
      },
      { 
        path: mdsisConstants.path_SoftwareFactory, 
        component: ServiceFabricaSofwareComponent, 
        data : mdsisConstants.data_SoftwareFactory
      },
      { 
        path: mdsisConstants.path_Automation, 
        component: ServiceAutomatizacionComponent, 
        data : mdsisConstants.data_Automation 
      },
      { 
        path: mdsisConstants.path_BusinessIntelligence, 
        component: ServiceInteligenciaNegocioComponent, 
        data: mdsisConstants.data_BusinessIntelligence 
      },
      { 
        path: mdsisConstants.path_ItConsulting, 
        component: ServiceConsultoriaComponent, 
        data : mdsisConstants.data_ItConsulting
      },
      { 
        path: mdsisConstants.path_ProjectManagement,
        component: ServiceGestionProyectosComponent, 
        data : mdsisConstants.data_ProjectManagement 
      },
      { 
        path: mdsisConstants.path_TalentManagement, 
        component: ServiceGestionTalentosComponent, 
        data : mdsisConstants.data_TalentManagement 
      },
      { 
        path: mdsisConstants.path_DigitalTransformation, 
        component: ServiceTransformacionDigitalComponent, 
        data : mdsisConstants.data_DigitalTransformation 
      },
      { 
        path: mdsisConstants.pathInvalid, 
        component: IndexModernBusinessComponent, 
        data: null
      },
    ]
  },
  { path: 'email-alert', component: EmailAlertComponent },
  { path: 'email-confirmation', component: EmailConfirmationComponent },
  { path: 'email-invoice', component: EmailInvoiceComponent },
  { path: 'email-password-reset', component: EmailPasswordResetComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
