import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transversales',
  templateUrl: './transversales.component.html',
  styleUrls: ['./transversales.component.css']
})
export class TransversalesComponent implements OnInit {


  constructor() {
  }
  
  ngAfterViewInit() {
  }

  ngOnInit(): void {
  }
}
