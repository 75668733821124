import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
import { NgxMasonryOptions } from 'ngx-masonry';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { Title, Meta } from '@angular/platform-browser';
import { mdsisCommon } from 'src/app/shared/common/mdsis.common';
import { mdsisConstants } from 'src/app/shared/common/mdsis.constants';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-page-aboutus-two',
  templateUrl: './page-aboutus-two.component.html',
  styleUrls: ['./page-aboutus-two.component.css']
})
export class PageAboutusTwoComponent implements OnInit {
  navClass = 'nav-light';

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    navigation: true,
    pagination: false
  };

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  public myOptions: NgxMasonryOptions = {
    horizontalOrder: true
  };

  filterredImages;
  galleryFilter = 'all';
  list = [
  {
    image: 'assets/images/work/clients/1.png',
    title: 'Carvajal Tecnología',
    text: 'Fábrica de Software',
    category: 'branding'
  },
  {
    image: 'assets/images/work/clients/2.png',
    title: 'Interrapidísimo',
    text: 'Fábrica de Software',
    category: 'designing'
  },
  {
    image: 'assets/images/work/clients/3.png',
    title: 'DNI Developers',
    text: 'Varios Servicios',
    category: 'photography'
  },
  {
    image: 'assets/images/work/clients/4.jpg',
    title: 'Comando de Reclutamiento Ejercito de Colombia',
    text: 'Varios Servicios',
    category: 'development'
  },
  {
    image: 'assets/images/work/clients/5.jpg',
    title: 'Lucta Grancolombiana',
    text: 'Varios Servicios',
    category: 'branding'
  },
  {
    image: 'assets/images/work/clients/6.gif',
    title: 'Transmilenio',
    text: 'Fábrica de Software',
    category: 'designing'
  },
  {
    image: 'assets/images/work/clients/7.png',
    title: 'Escuela Superior de Guerra',
    text: 'Varios Servicios',
    category: 'branding'
  },
  {
    image: 'assets/images/work/clients/8.jpg',
    title: 'Zencillo Software',
    text: 'Varios Servicios',
    category: 'development'
  },
  {
    image: 'assets/images/work/clients/9.jpg',
    title: 'Easy Services',
    text: 'Fábrica de Software',
    category: 'photography'
  },
  {
    image: 'assets/images/work/clients/10.jpg',
    title: 'Domésticas de Colombia',
    text: 'Varios Servicios',
    category: 'photography'
  },
  {
    image: 'assets/images/work/clients/11.jpg',
    title: 'Bricks4Us',
    text: 'Varios Servicios',
    category: 'photography'
  }
  ];

  lightboximgs = [
    'assets/images/work/clients/1.png',
    'assets/images/work/clients/2.png',
    'assets/images/work/clients/3.png',
    'assets/images/work/clients/4.jpg',
    'assets/images/work/clients/5.jpg',
    'assets/images/work/clients/6.gif',
    'assets/images/work/clients/7.png',
    'assets/images/work/clients/8.jpg',
    'assets/images/work/clients/9.jpg',
    'assets/images/work/clients/10.jpg',
    'assets/images/work/clients/11.jpg',
  ];

  constructor(private modalService: NgbModal,
    private scrollToService: ScrollToService,
    public title: Title, private meta: Meta) { }

  ngOnInit(): void {
    mdsisCommon.setMetaTags(this.title,this.meta, mdsisConstants.data_AboutUs);
    this.filterredImages = this.list;
    this.scrollToService.scrollTo({ target: 'home' });
  }

  /**
    * Open modal for show the video
    * @param content content of modal
    */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
