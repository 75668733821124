import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { Title, Meta } from '@angular/platform-browser';
import { mdsisCommon } from 'src/app/shared/common/mdsis.common';
import { mdsisConstants } from 'src/app/shared/common/mdsis.constants';

@Component({
  selector: 'app-service-consultoria',
  templateUrl: './service-consultoria.component.html',
  styleUrls: ['./service-consultoria.component.css']
})
export class ServiceConsultoriaComponent implements OnInit {
  navClass = 'nav-light';
  constructor(private modalService: NgbModal,
    private scrollToService: ScrollToService,
    public title: Title, private meta: Meta) { }

  ngOnInit(): void {
    mdsisCommon.setMetaTags(this.title,this.meta, mdsisConstants.data_ItConsulting);
    this.scrollToService.scrollTo({ target: 'home' });
  }
  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
}
