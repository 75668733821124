import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Api } from 'src/app/shared/providers/api/api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Title, Meta } from '@angular/platform-browser';
import { mdsisCommon } from 'src/app/shared/common/mdsis.common';
import { mdsisConstants } from 'src/app/shared/common/mdsis.constants';

@Component({
  selector: 'app-page-contact-three',
  templateUrl: './page-contact-three.component.html',
  styleUrls: ['./page-contact-three.component.css']
})
export class PageContactThreeComponent implements OnInit {
  navClass = 'nav-light';
  @ViewChild('contentConfirmation') contentConfirmation: ElementRef;
  ContactForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    email: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    subject: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    comments: new FormControl('', [Validators.required, Validators.maxLength(2000)]),
  });

  constructor(private scrollToService: ScrollToService,
    private modalService: NgbModal,
    private api: Api,
    public title: Title, private meta: Meta) { }

  ngOnInit(): void {
    mdsisCommon.setMetaTags(this.title,this.meta, mdsisConstants.data_ContactUs);
    this.scrollToService.scrollTo({ target: 'home' });
  }

  onFormSubmit(): void {
    if (!this.ContactForm.invalid) {
      var obj = { 
        fullname: this.ContactForm.controls.name.value,
        email: this.ContactForm.controls.email.value,
        subject: this.ContactForm.controls.subject.value,
        message: this.ContactForm.controls.comments.value,
      };
      this.api.post<any>(environment.UrlServiceSendEmail, obj).subscribe(fm => {  
        this.mostrarConfirmacion();
      },
        (err: any) => {
          if(err.status == 502){
            this.mostrarConfirmacion();
          }
        }
      );
    } else {
      //this.luctaMessageService.messageError('Existe información obligatoria sin diligenciar');
    }
  }

  mostrarConfirmacion(){
    this.modalService.open(this.contentConfirmation, { centered: true });
    this.limpiarFormulario();
  }

  limpiarFormulario(){
    this.ContactForm.controls.name.setValue("");
    this.ContactForm.controls.email.setValue("");
    this.ContactForm.controls.subject.setValue("");
    this.ContactForm.controls.comments.setValue("");
  }

}
